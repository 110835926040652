<template>
  <div class="worker-applications my-2">
    <div v-if="isLoading">
      <v-skeleton-loader
        data-test="loader"
        v-for="i in 5"
        :key="i"
        type="list-item-avatar"
      />
    </div>
    <v-list-item
      v-else
      v-for="application in items"
      :key="application.id"
      @click="() => handleView(application)"
    >
      <WorkerApplicationItem :application="application" />
    </v-list-item>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { get } from "lodash";
import WorkerApplicationItem from "@/views/workers/WorkerDetails/components/WorkerApplicationItem";

export default {
  components: {
    WorkerApplicationItem
  },
  computed: {
    ...mapState("workers", {
      applications: state => state.applications,
      isLoading: state => state.isFetchingApplications
    }),
    items() {
      return this.applications.map(item => ({
        status: item.status,
        organisation: item.organisation && item.organisation.name,
        booking: {
          id: this.getBookingItem(item, "id"),
          title: this.getBookingItem(item, "title")
        }
      }));
    }
  },
  methods: {
    getBookingItem(application, key) {
      return get(application.booking, key) || "None";
    },
    handleView({ booking }) {
      this.$router.push({
        name: "bookingDetails",
        params: { id: booking.id }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.worker-applications {
  margin: 0 -15px;
}
</style>
