<template>
  <div>
    <h1>Applications</h1>
    <WorkerApplications class="mb-4" />
    <h1>Timesheets</h1>
    <WorkerTimesheets />
  </div>
</template>

<script>
import WorkerApplications from "@/views/workers/WorkerDetails/components/WorkerApplications";
import WorkerTimesheets from "@/views/workers/WorkerDetails/components/WorkerTimesheets";

export default {
  name: "WorkerActivity",
  components: {
    WorkerApplications,
    WorkerTimesheets
  }
};
</script>
