<template>
  <div class="list-item with-icon">
    <v-list-item-avatar min-width="60px" height="60px" tile>
      <v-img
        height="30px"
        contain
        src="@/assets/svg/application_black_icon.svg"
      />
    </v-list-item-avatar>
    <v-list-item-content>
      <div class="item-right">
        <div class="description py-1">
          <div
            class="d-flex align-baseline"
            :class="{ 'flex-column': isMobile }"
          >
            <h3>{{ application.booking.title }}</h3>
            <span class="text-mono ml-2"> B-{{ application.booking.id }} </span>
          </div>
          <div class="d-flex">
            <span>{{ application.organisation }}</span>
          </div>
        </div>
        <div class="d-flex" :class="{ 'flex-column': isMobile }">
          <Chip
            :color="getColorByStatus(application.status)"
            class="chip--status"
            shrink
          >
            {{ application.status | upperCase }}
          </Chip>
        </div>
      </div>
    </v-list-item-content>
  </div>
</template>

<script>
import { getColorByStatus } from "@/utils/bookings";
import Chip from "@/components/common/Chip";

export default {
  components: {
    Chip
  },
  props: {
    application: Object
  },
  methods: {
    getColorByStatus
  }
};
</script>

<style lang="scss">
.worker-applications {
  margin: 0 -15px;

  .list-item .v-avatar.v-list-item__avatar {
    background-color: transparentize($nav-link-color, 0.9);
  }
}
</style>
