<template>
  <div class="worker-applications my-2">
    <div v-if="isLoading">
      <v-skeleton-loader
        data-test="loader"
        v-for="i in 5"
        :key="i"
        type="list-item-avatar"
      />
    </div>
    <v-list-item
      v-else
      v-for="timesheet in items"
      :key="timesheet.id"
      @click="() => handleView(timesheet)"
    >
      <WorkerTimesheetItem :timesheet="timesheet" />
    </v-list-item>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { GET_ALL_TIMESHEETS } from "@/store/modules/timesheets/actions";
import { TIMESHEETS_NAMESPACE } from "@/store/modules/timesheets";
import { formatDateRange } from "@/utils/time";
import WorkerTimesheetItem from "@/views/workers/WorkerDetails/components/WorkerTimesheetItem";
import { paginationMixin } from "@/mixins/pagination.mixin";

const {
  mapState: timesheetMapState,
  mapActions: timesheetMapActions
} = createNamespacedHelpers(TIMESHEETS_NAMESPACE);

export default {
  mixins: [paginationMixin],
  components: {
    WorkerTimesheetItem
  },
  data() {
    return {
      filters: { worker_id: this.$route.params.id }
    };
  },
  computed: {
    ...timesheetMapState({
      timesheets: state => state.timesheets,
      isLoading: state => state.isFetchingTimesheets
    }),
    items() {
      return this.timesheets.map(item => ({
        ...item,
        dateRange: formatDateRange(
          item.weekCommencing,
          item.weekEnding,
          "Do MMMM YYYY"
        )
      }));
    },
    requestParams() {
      return {
        filter: this.filters,
        "page[size]": 1000,
        "page[number]": 1,
        include: ""
      };
    }
  },
  methods: {
    ...timesheetMapActions({
      getTimesheets: GET_ALL_TIMESHEETS
    }),
    async fetchData() {
      const { meta } = await this.getTimesheets(this.requestParams);
      this.updatePageDetails(meta);
    },
    handleView({ id }) {
      const workerId = this.$route.params.id;
      this.$router.push({
        name: "timesheetDetailsFromWorkerActivity",
        params: { id, workerId }
      });
    }
  },
  created() {
    this.fetchData();
  }
};
</script>

<style lang="scss" scoped>
.worker-applications {
  margin: 0 -15px;
}
</style>
