<template>
  <div class="list-item with-icon">
    <v-list-item-avatar min-width="60px" height="60px" tile>
      <v-img height="30px" contain src="@/assets/svg/stopwatch.svg" />
    </v-list-item-avatar>
    <v-list-item-content>
      <div class="item-right">
        <div class="description py-1">
          <h3>{{ timesheet.title }}</h3>
          <span>{{ timesheet.dateRange }}</span>
        </div>
        <div class="d-flex flex-column align-end py-1">
          <span class="text-mono"> CSA-{{ timesheet.id }} </span>
          <Chip
            :color="getColorByStatus(timesheet.status)"
            class="chip--status"
            shrink
          >
            {{ timesheet.status | upperCase }}
          </Chip>
        </div>
      </div>
    </v-list-item-content>
  </div>
</template>

<script>
import { getColorByStatus } from "@/utils/timesheets";
import Chip from "@/components/common/Chip";

export default {
  components: {
    Chip
  },
  props: {
    timesheet: Object
  },
  methods: {
    getColorByStatus
  }
};
</script>
